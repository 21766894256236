<template>
  <div class="scroll-item">
      <p>{{ $t("scroll")}}</p>
      <img src="./../assets/scroll-down.png" alt="scroll" />
  </div>
</template>

<script>
export default {
    name : "ScrollDown"
}
</script>

<style lang="scss" scoped>
 .scroll-item {
     margin: 40px auto;
     display: flex;
     flex-direction: column;
     align-items: center;
     animation: scale 5s infinite;
}

p {
    color:#fff;
    text-align: center;
    margin:0;
}

@keyframes scale {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 768px) {
    .scroll-item {
        margin: 20px auto;
    }

    p {
        font-size:13px;
    }

    img {
        width:30px;
    }
}
</style>