<template>
    <router-link :to="{ name : item.name}" class="product-item">
        <img :src="item.img" :alt="item.name" class="product-img" />
        <div class="product-sep"></div>
        <h3><span class="colored">Steri</span>Spine<sup>TM</sup><span class="colored">{{ item.name }}</span></h3>
        <div class="scroll-container">
            <img src="./../assets/scroll-up.png" alt="scroll" />
        </div>
    </router-link>
</template>

<script>
export default {
    name :"ProductRangeItem",
    props : ['item']
}
</script>

<style scoped lang="scss">
    .product-item {
        width:calc(33% - 40px);
        margin:20px;
        background:rgba(255,255,255,0.05);
        padding:30px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.5s;
    }

    .product-item:hover {
        background:rgba(255,255,255,0.01);
    }

    .product-img {
        max-width: 80%;
    }

    h3 {
        font-size:30px;
        color:#fff;
        font-weight: 300;
    }
    
    sup {
        font-size:15px;
    }

    h3 .colored {
        color:$mainColor;
    }

    .product-sep {
        margin:40px auto 0 auto;
        height:1px;
        width:30%;
        background:$mainColor;
    }

    .scroll-container {
        height:50px;
    }

     .scroll-container img {
         transition: 0.5s;
         margin-top:25px;
         width:40px;
     }

     .product-item:hover .scroll-container img {
         margin-top: 0;
     }

     @media screen and (max-width:1280px) {
         .product-item {
             width:calc(90% - 40px);
             max-width: 350px;
         }
     }
</style>