<template>
    <section class="section-full" :style="{background : 'url(' + product.bg + ')'}">
        <div class="box">
            <div class="row">
                <div class="col-50 col-img">
                    <template v-if="product.img.length == 1">
                        <img :src="product.img[0]" :alt="product.name" class="product_img" />
                    </template>
                    <template v-else>
                        <carousel :autoplay="5000" :wrap-around="false">
                            <slide v-for="slide in product.img" :key="slide">
                                <div class="carousel__item">
                                    <img :src="slide" :alt="product.name" class="product_img" />
                                </div>
                            </slide>

                            <template #addons>
                            <Pagination />
                            </template>
                        </carousel>
                    </template>
                </div>
                <div class="col-50 ">
                    <h2 v-html="product.name" :class="[ {'title-special' : special }]"></h2>
                    <div class="bullets-container">
                        <div class="bullets-sep"></div>
                        <div class="bullets-item" v-for="(item, idx) in product.bullets" :key="idx">
                            <i class="fa-solid fa-chevron-right chevron-icon"></i>
                            <p v-html="item"></p>
                        </div>
                        <div class="bullets-sep"></div>
                    </div>
                    <template v-if="product.video">
                        <div class="btn-video" @click="displayVideo = true"> <i class="fa-solid fa-circle-play play-icon"></i> {{ $t("product.btn")}}</div>
                        <modal-video 
                            :video="product.video" 
                            v-if="displayVideo"
                            v-on:close="displayVideo = false"
                        ></modal-video>
                    </template>
                </div>
            </div>
        </div>
    </section>
        
</template>

<script>
import ModalVideo from "./ModalVideo.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name : "Product",
    props : ["product"],
    components : { ModalVideo, Carousel, Slide, Pagination},
    data() {
        return {
            special : false,
            displayVideo : false
        }
    }, 
    mounted() {
        if(this.product.special) {
            this.special = true;
        }
    }
}
</script>

<style scoped lang="scss">
    h2 {
        color:#fff;
        text-align: left;
        margin: 0 0 40px 0;
        font-size:40px;
        font-weight: 400;
    }

    .section-full  {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    h2.title-special {
        font-size:90px;
    }

    .row {
        align-items: center;
    }

    .col-50.col-img {
        display: flex;
        justify-content: center;
    }

    p {
        margin:0;
        color:#fff;
        font-size:20px;
        font-weight:300;
        line-height:1.5em;
        position:relative;
    }

    .chevron-icon {
        font-size: 20px;
        color:$mainColor;
        margin-right:20px;
        margin-top:5px;
    }

    .bullets-item {
        display: flex;
        align-items: flex-start;
        margin-bottom:20px;
    }

    .bullets-sep {
        width:50%;
        height:1px;
        background:$mainColor;
        margin-top:20px;
        margin-bottom: 20px;
    }

    .btn-video {
        color:$mainColor;
        text-transform: uppercase;
        font-size:20px;
        font-weight:300;
        padding:18px 30px;
        border:1px solid $mainColor;
        cursor: pointer;
        transition:0.5s;
        display: inline-block;
    }

    .btn-video:hover {
        background:$mainColor;
        color:#fff;
    }

    .play-icon {
        color:$mainColor;
        font-size:25px;
        margin-right:10px;
        transition:0.5s;
    }

    .btn-video:hover .play-icon {
        color:#fff;
    }

    .product_img {
        max-width: 80%;
        margin:0 auto;
    }

    .carousel__item {
        width:90%;
        margin:0 auto;
    }

    @media screen and (max-width:768px) {
        p {
            font-size:15px;
        }

        h2.title-special {
            font-size:70px;
        }

        h2 {
            font-size:30px;
            margin-bottom:10px;
        }

        .section-full {
            margin:80px 0;
            background:none !important;
        }

        .btn-video {
            font-size:18px;
            padding: 12px 20px;
        }

        .row {
            flex-direction: c;
        }

        .col-50 {
            margin:20px 0;
        }
    }
</style>