<template>
    <div class="modal" v-if="!isPro">
        <div class="modal-container">
            <p>{{ $t("modalPro.isPro")}}</p>
            <div class="btns-container">
                <div class="btn" @click="setPro">{{ $t("modalPro.yes")}}</div>
                <router-link :to="{ name : 'Home'}" class="btn btn-second">{{ $t("modalPro.no")}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
    name : "ModalPro",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data() {
        return {
            isPro : false
        }
    },
    mounted() {
        this.isPro = this.cookies.get("safePro");
    },
    methods : {
        setPro() {
            this.cookies.set("safePro", true, 0);
            this.isPro = true;
        }
    }
}
</script>

<style scoped lang="scss">
    .modal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        text-align: center;
        color:#fff;
        font-size:18px;
        margin:0;
        font-weight: 300;        
    }

    .btns-container {
        margin-top:10px;
        display: flex;
        justify-content: center;
    }

    .btn {
        margin:0 5px;
        border-radius:5px;
        width:80px;
        text-align: center;
        text-transform: uppercase;
    }

</style>