<template>
    <section class="section-full">
        <div class="box">
            <h2>{{ $t("productRange.title")}}</h2>
            <div class="products-range">
                <product-range-item
                    v-for="(item, idx) in list"
                    :key="idx"
                    :item="item"
                >
                </product-range-item> 
            </div>
        </div>
    </section>
</template>

<script>
import ProductRangeItem from "./ProductRangeItem.vue";

export default {
    name : "ProductRange",
    props : ['product'],
    components : { ProductRangeItem },
    data() {
        return {
            products : [
                {
                    name : "PS",
                    img : require("@/assets/ps-range.png")
                },
                {
                    name : "CC",
                    img : require("@/assets/cc-range.png")
                },
                {
                    name : "LC",
                    img : require("@/assets/lc-range.png")
                },
                {
                    name : "VA",
                    img : require("@/assets/va-range.png")
                },

            ]
        }
    },
    computed : {
        list() {
            if(this.product) {
                return this.products.filter(e => e.name != this.product);
            } else {
                return this.products;
            } 
        
        } 
    },
    mounted() {
        this.products = this.products.sort(() => Math.random() - 0.5);
    }
}
</script>

<style scoped lang="scss">
    .section-full {
        background-image: url("./../assets/product_range.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        color:#fff;
        margin:80px 0 0 0;
        font-size:35px;
        font-weight:400;
        text-align: center;
    }

    .products-range {
        display:flex;
        justify-content: center;
        width: 100%;
        margin-top:80px;
        margin-bottom:80px;
    }

     @media screen and (max-width:1280px) {
         .products-range {
             flex-direction: column;
             align-items: center;
         }

     }

</style>